/* eslint-disable react/prop-types */
import React from 'react';
import { Review } from '@autovia-uk/polaris-components/components/organisms/Review';
import { Price } from '@autovia-uk/polaris-components/components/atoms/Price';

export const getReviewBlock = (props) => {
  const {
    reviewIndex,
    title,
    url,
    image,
    description,
    allReviewsLabel = 'Read reviews',
    allReviewsLabelExtraClassNames,
    allReviewsUrl,
    allReviewsWrapperExtraClassNames,
    ratings,
    extraClassNames = { '-content-body': true },
    showCustomRating = false,
    priceOptions = null,
    watchVideo,
  } = props;

  const priceComponent = () => {
    const { min = 0, max = 0 } = priceOptions || {};

    if (min === 0 && max === 0) return null;

    return (
      <p>
        <b>Price</b>
        :&nbsp;
        <Price {...priceOptions} />
      </p>
    );
  };

  return (
    <Review
      reviewIndex={reviewIndex || null}
      title={title || ''}
      image={image || null}
      description={description || ''}
      url={url || null}
      readMoreLabel=""
      allReviewsLabel={allReviewsUrl && allReviewsLabel ? allReviewsLabel : ''}
      allReviewsLabelExtraClassNames={allReviewsLabelExtraClassNames}
      allReviewsUrl={allReviewsUrl || null}
      allReviewsWrapperExtraClassNames={allReviewsWrapperExtraClassNames}
      ratings={ratings || null}
      showCustomRating={showCustomRating}
      extraClassNames={extraClassNames}
      watchVideo={watchVideo}
    >
      {priceComponent()}
    </Review>
  );
};
